import {defineStore} from 'pinia'
import Roles from "~/utils/roles"
import type {OutputSession} from "~/models"

export const useSessionsStore = defineStore('sessions', () => {
    const {user} = useAuth()
    const sessions: Ref<OutputSession[]> = ref([])

    const load = async () => {
        if (user.value.role === Roles.VIEWER.value) {
            return
        }

        sessions.value = await useApi().getSessions()
    }

    const unload = async () => {
        sessions.value = []
    }

    return {sessions, load, unload}
})